<template>
  <v-container>
    <v-row
      class="text-center"
      style="height: 100px"
    >
      <v-col cols="6 d-flex align-center">
        <v-img
          alt="Kribel Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="40"
        />
        <div class="text-h4">KRIBEL HUMANITY BLOCKCHAIN</div>
      </v-col>
      <v-col cols="6 d-flex align-center justify-end">
        <v-btn
          class="ma-2"
          outlined
          @click.stop="investModalShown = true"
        >Invest</v-btn>

        <v-btn
          class="ma-2"
          outlined
          jastify="right"
          href="mailto:contact@Kribel.com
            ?subject=Web contact
            &body=Hello!"
        >Contact
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center" style="height: 250px">
      <v-col
        class="purple d-flex flex-column align-center justify-center"
        cols="6"
      >
        <v-img
          alt="Kribel Wallet"
          class="shrink mr-2"
          contain
          :src="require('@/assets/wallet.png')"
          transition="scale-transition"
          width="180"
        />
      </v-col>
      <v-col cols="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h4 font-weight-light">
          Universal Basic Income Wallet
        </span>
        <v-divider class="purple" width="452"></v-divider>
        <span class="text-h6 font-weight-light">
          App wallet for cryptocurrency transactions and UBI. <br />
          Prefilled with 1 ONE Kribel on registration. <br />
          Based on government issued electronic ID.
        </span>
      </v-col>
    </v-row>

    <v-row class="text-center" style="height: 250px">
      <v-col cols="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h4 font-weight-light"> Kribel Cryptocurrency </span>
        <v-divider class="purple" width="320"></v-divider>
        <span class="text-h6 font-weight-light">
          Decentralized digital currency. Amount is limited in the code <br />
          to 7.5 billion coins as of 2021 with defined in the code <br />
          exponential growth over the years by UN prediction.<br />
          1 Kribel equal 100 Credits. 1 Credit equal 100 Cents.
        </span>
      </v-col>
      <v-col
        class="purple d-flex flex-column align-center justify-center"
        cols="6"
      >
        <v-img
          alt="Kribel Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo_white.png')"
          transition="scale-transition"
          width="180"
        />
      </v-col>
    </v-row>

    <v-row class="text-center" style="height: 250px">
      <v-col
        class="purple d-flex flex-column align-center justify-center"
        cols="6"
      >
        <v-img
          alt="Kribel Wallet"
          class="shrink mr-2"
          contain
          :src="require('@/assets/eid.png')"
          transition="scale-transition"
          width="180"
        />
      </v-col>
      <v-col cols="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h4 font-weight-light">
          Decentralized Personalization
        </span>
        <v-divider class="purple" width="445"> </v-divider>
        <span class="text-h6 font-weight-light">
          Claiming your Kribel coin possible only with government <br />
          electronic ID. Upon registration wallet programmatically <br />
          is bind with eID for future decentralized personalization.<br />
          Then UBI payments can be made by ANY government <br />
          to ANY human being.
        </span>
      </v-col>
    </v-row>

    <v-row class="text-center" style="height: 250px">
      <v-col cols="6" class="d-flex flex-column align-center justify-center">
        <span class="text-h4 font-weight-light"
          >UN Sustainable Development Goal</span
        >
        <v-divider class="purple" width="515"></v-divider>
        <span class="text-h6 font-weight-light"
          >End poverty in all its forms everywhere. This is number 1<br />
          Sustainable Development Goal defined by UN to be addressed<br />
          till the year of 2030. Kribel is devoting itself to this high aim.<br />
          Each human on Earth is able to create digital wallet and get<br />
          their 1 ONE Kribel coin forecasted rising in cost.
        </span>
      </v-col>
      <v-col
        class="purple d-flex flex-column align-center justify-center"
        cols="6"
      >
        <v-img
          alt="Kribel Wallet"
          class="shrink mr-2"
          contain
          :src="require('@/assets/un.png')"
          transition="scale-transition"
          width="210"
        />
      </v-col>
    </v-row>

    <Invest :investModalShown.sync="investModalShown" />
  </v-container>
</template>

<script>
import Invest from '@/modals/Invest.vue';

export default {
  name: 'Home',
  components: {
    Invest,
  },
  data() {
    return {
      investModalShown: false,
    };
  },
};
</script>
